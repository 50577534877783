import { Application } from "@hotwired/stimulus"

const application = Application.start()

import "./src/jquery"
import {} from 'jquery-ujs'
import "jquery-ui"
import * as bootstrap from "bootstrap"

// Configure Stimulus development experience
application.debug = false
window.Stimulus   = application

export { application }
